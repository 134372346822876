<template>
  <div
    class="field"
    :class="{ 'is-horizontal': labelForInput, 'is-vertical': !labelForInput }"
  >
    <div
      v-if="showLabel && label"
      class="field-label is-normal has-text-left"
    >
      <label
        ref="label"
        class="label"
      >
        {{ label }}
        <span
          class="has-text-danger"
          v-if="required"
        >*</span>
      </label>
    </div>
    <div class="field-body">
      <div class="field">
        <div
          class="control flex"
          :class="{ 'has-icons-left': iconLeft, 'has-icons-right': iconRight }"
        >
          <slot name="symbol-before-input"></slot>
          <input
            :value="value"
            :type="fieldType"
            :class="[{
              'is-success': hasPassedValidation,
              'is-danger': hasError,
              'is-borderless': !editMode,
            },
            inputCssClass]"
            :placeholder="placeholder"
            :disabled="disabledStatus"
            class="input"
            autocomplete="none"
            @blur="onBlur"
            @keyup="onChange"
            @focus="onFocus"
            @input="onInput"
          />
          <slot name="symbol-after-input"></slot>
        </div>
        <div v-if="error">
          <span
            v-if="!Array.isArray(error)"
            class="help is-danger"
            v-html="error"
          ></span>
          <div
            v-else
            class="help is-danger"
          >
            <div
              v-for="(message, index) in error"
              :key="`e${index}`"
              v-html="message"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInputFieldController from '@/components/UI/Form/BaseInputFieldController.vue'
export default {
  extends: BaseInputFieldController,
  props: {
    editMode: {
      type: Boolean,
      default: true,
    },
    iconLeft: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    labelForInput: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    percentage: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/template.scss";
.flex {
  display: flex;
  align-items: center;
}
.is-borderless {
  padding-left: 0;
  border-color: transparent;
  &:hover {
    border-color: transparent;
  }
}

.field.is-vertical {
  .label {
    margin-bottom: 0.5em;
  }
}

@media screen and (min-width: $desktop - ($gap * 2)) {
  .field.is-horizontal {
    display: flex;
    align-items: center;

    .field-label {
      padding-top: unset;
      flex-basis: 150px;
      flex-grow: 0;
    }
  }
}
@media screen and (max-width: $desktop - ($gap * 2)) {
  .field.is-horizontal {
    display: block;

    .field-label {
      flex-basis: unset;
      flex-grow: unset;
      padding-top: 0.375em;
      margin-bottom: 0.5rem;
    }
  }
}
</style>
